.job {
  &_title {
    padding: 110px 0 0 0;
    font-family: "Ubuntu Regular", sans-serif;
    font-size: 40px;
    line-height: 1.2;
    font-weight: normal;
    text-align: center;
    margin-bottom: 0;

    @media screen and (max-width: 600px) {
      padding: 67px 0 20px 0;
    }
  }
}