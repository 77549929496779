.section {

}

.df {
  display: flex;
}

.fdr {
  flex-direction: row;
}

.fdc {
  flex-direction: column;
}

.jcsb {
  justify-content: space-between;
}

.left {
  position: relative;
  @media screen and (max-width: 768px) {
    position: fixed;
    left: 0;
    top: 81px;
    width: 300px;
    background: #432671;
    height: calc(100% - 81px);
    transition: .5s;
    padding: 10px;
    z-index: 101;
    transform: translateX(-100vw);
  }
}

.step_way {
  position: absolute;
  left: 0;
  top: 0;
  width: 46px;
  height: 46px;
  border-radius: 200px;
  border-width: 2px;
  border-color: rgb(157, 166, 177);
  border-style: solid;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.step_way__text {
  font-size: 22px;
  font-family: "Ubuntu Medium", sans-serif;
  color: rgb(67, 38, 113);
  line-height: 1.2;
}

.left_active {
  transform: translateX(0);
}

.left_title {
  font-size: 26px;
  font-family: "Ubuntu Regular", sans-serif;
  color: rgb(0, 12, 18);
  line-height: 2.654;
  text-align: left;

  @media screen and (max-width: 768px) {
    color: #fff;
  }
}

.left_step {
  font-size: 15px;
  font-family: "Ubuntu", sans-serif;
  color: #9da6b1;
  transition: .5s;
  line-height: 1.2;
  text-align: left;
  cursor: pointer;
  padding-left: 35px;
  position: relative;
  margin: 23px 0;
  font-weight: 500;
  @media screen and (max-width: 1024px) {
    padding-left: 0;
    line-height: 1.4;
  }
}

.left_step:not(:last-child) {
  margin-bottom: 25px;
}

.side_bar_active {
  position: fixed;
  left: unset;
  top: 84px;
  height: max-content;
  @media screen and (max-width: 768px) {
    position: relative;
    top: 0;
  }
}

.side_bar_under_active {
  position: absolute;
  bottom: 17px;
  left: 0;
  top: unset;
  @media screen and (max-width: 768px) {
    position: relative;
  }
}

.left_step_active {
  color: rgb(67, 38, 113);
  @media screen and (max-width: 768px) {
    color: #fff;
  }

  &:before {
    @media screen and (min-width: 1025px) {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: url("/img/article/arrow.png") no-repeat left;
      width: 35px;
    }
  }
}

.right {
  max-width: 866px;
  width: 100%;
}


.step_title {
  font-size: 20px;
  font-family: "Ubuntu Regular", sans-serif;
  color: rgb(157, 166, 177);
  line-height: 1.2;
}

.nav_ico {
  position: fixed;
  left: 0;
  height: 45px;
  width: 45px;
  top: 25vh;
  background: #9da6b1;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 27px;
  }
}

.nav_ico_background {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  background: #000;
  opacity: 0;
  cursor: pointer;
  transition: .5s;
}

.nav_ico_background_active {
  opacity: .5;
}

.item {
  padding-left: 100px;
  width: calc(100% - 100px);
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 45px;

  &:not(:nth-last-child(2)) {
    @media screen and (min-width: 1025px) {
      &:before {
        content: '';
        position: absolute;
        left: 22px;
        top: 46px;
        width: 2px;
        height: 100%;
        background-color: rgb(157, 166, 177);
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
    width: calc(100% - 20px);
  }
}

.item_title {
  font-size: 24px;
  font-family: "Ubuntu Regular", sans-serif;
  color: #432671;
  line-height: 1.2;
}

.item_desc {
  font-size: 18px;
  font-family: "Roboto Regular", sans-serif;
  color: rgb(0, 12, 18);
  line-height: 1.2;
  margin-top: 20px;
}

.items_under_desc {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: rgb(0, 12, 18);
  font-style: italic;
  line-height: 1.2;
  padding-left: 100px;
  width: calc(100% - 100px);
  @media screen and (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
}