.title {
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  color: rgb(67, 38, 113);
  line-height: 1.2;
  font-weight: 500;
}

.custom_checkbox_label {
  position: relative;
  display: flex;
  align-items: center;
}

.custom_checkbox_label_text {
  font-size: 16px;
  font-family: "Ubuntu", sans-serif;
  color: rgb(0, 0, 0);
  line-height: 1.2;
  padding-left: 15px;
  cursor: pointer;
  display: block;
}

.custom_checkbox {
  display: block;
  border-radius: 5px;
  background-color: rgb(217, 219, 233);
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    position: absolute;
    background: url("../../../img/survey/check_purple.svg") center no-repeat;
    background-size: 70%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .3s;
    z-index: 1;
  }
}

.custom_checkbox_input {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: -3;
  top: 0;
  height: 0;
  width: 0;
  overflow: hidden;

  &:checked + .custom_checkbox {
    &:before {
      opacity: 1;
    }
  }
}

.custom_range_container {
  display: flex;
  margin-top: 40px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.custom_range_left {
  width: 353px;
  position: relative;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.custom_range_right {
  margin-left: 50px;
  position: relative;
  width: 353px;
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }
}

.custom_range_content {
  display: flex;
  flex-direction: column;
  position: relative;
}

.custom_range_title {
  font-size: 16px;
  font-family: "Ubuntu", sans-serif;
  color: rgb(0, 0, 0);
  line-height: 1.2;
}

.form_btn_block {
  margin-right: 0;
  margin-left: auto;

  @media screen and (max-width: 700px) {
    margin-right: auto;
  }
}

.text_container {
  display: flex;
  padding-bottom: 100px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.text_container_item {
  width: calc(33% - 22.5px);

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  &:not(:last-child) {
    margin-right: 45px;

    @media screen and (max-width: 800px) {
      margin-right: 0;
      padding-bottom: 70px;
    }
  }
}

.text_container_item_title {
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  color: rgb(67, 38, 113);
  font-weight: 500;
  line-height: 1.2;
}

.text_container_item_desc {
  &, & * {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: rgb(0, 12, 18);
    line-height: 1.4;
  }

  ul {
    li {
      padding-left: 17px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border: 2px solid rgb(67, 38, 113);
        background: #fff;
        border-radius: 50%;
      }
    }
  }
}

.text_main_title {
  font-size: 40px;
  font-family: "Ubuntu", sans-serif;
  color: rgb(0, 0, 0);
  width: 100%;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 65px;
  margin-top: 88px;


  @media screen and (max-width: 800px) {
    font-size: 2em;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 35px;
  }
}

.feedback_form__input {
  height: 45px !important;
  resize: none !important;
  @media screen and (max-width: 800px) {
    height: 50px !important;
  }
}

.errorMessage {
  color: red;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  margin-top: 5px;
  display: none;


}

.activeErrorMessage {
  display: block;
}

.feedback_form_file {
  @media screen and (max-width: 800px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.attach_file_btn {

  &:before {
    left: 5px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    margin: 5px 0;
    align-items: flex-start;
    .sub-desc {
      text-align: left;
    }
  }
}

.btn_upper {
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
    z-index: -1;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    max-width: 100%;
  }
}

.re_capcha {
  @media screen and (max-width: 700px) {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.modal_massage__container {
  @media screen and (max-width: 700px) {
    width: 100%;
    padding-bottom: 0;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

.section_bg {
  @media screen and (max-width: 700px) {
    width: 100vw;
  }
}

.modal_massage__title {
  @media screen and (max-width: 700px) {
    text-align: center !important;
  }
}

.top_nav__btn {
  @media screen and (max-width: 700px) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.custom_range_left_currency_label {
  position: relative;
  cursor: pointer;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.custom_range_left_currency {;
}

.custom_range_left_currency_label_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  background-color: #d9dbe9;
  height: 35px;
  border-radius: 35px;
}

.custom_range_left_currency_input_text {
  font-size: 16px;
  font-family: "Ubuntu", sans-serif;
  color: rgb(0, 0, 0);
  line-height: 1.2;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 35px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: block;
  }
}

.custom_range_left_currency_input {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -3;
  overflow: hidden;

  &:checked + .custom_range_left_currency_input_text {
    background-image: linear-gradient(29deg, #432671 0%, #2a2671 100%);
    color: #fff;
  }
}