$size: 40px;

.smile_container {
	display: flex;
	flex-direction: column;
	position: relative;
	height: $size;
	width: $size;
}

.smile {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

}

.icon {
	height: $size;
	width: $size;
	font-size: $size;
	line-height: 1;
	text-align: center;
	position: absolute;
	left: 0;
	top: 0;
	transition: .15s linear;
	transform: scale(1.5);
	opacity: 0;
}

.icon_active {
	opacity: 1;
	transform: scale(1);
}

.icon_active_finish {
	animation-name: icon_finish_animation;
	animation-play-state: running;
	animation-duration: 600ms;
	opacity: 1;
	transform: scale(1);
}

@keyframes icon_finish_animation {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	50% {
		opacity: .5;
		transform: scale(1.6);
	}

	75% {
		opacity: .8;
		transform: scale(.8);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}


