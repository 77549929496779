
.drop_down {
  &_container {
    border-bottom: 2px solid #e3e4e5;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    position: relative;
    cursor: pointer;
    width: 282px;
    //z-index: 0;
    padding-bottom: 9px;
    height: 32px;

    @media screen and (min-width: 630px) {
      margin-right: 20px;
    }


    @media screen and (max-width: 660px) {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
      z-index: 3;
    }
  }

  &_type {
    font-size: 17px;
    white-space: nowrap;
    font-family: Ubuntu Regular, sans-serif;
    color: #9da6b1;
    line-height: 1.2;

    @media screen and (max-width: 768px) {
      font-size: 19px;
    }
  }

  &_list {
    display: flex;
    flex-direction: column;
    height: max-content;
    max-height: 227px;
    cursor: pointer;
    padding: 2px 0;

    &_item {
      font-size: 17px;
      font-family: "Ubuntu", sans-serif;
      line-height: 1.2;
      margin-bottom: 20px;

      &:hover {
        color: #432671;
        font-weight: bold;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__select {
      position: absolute;
      left: 0;
      z-index: 11;
      top: 0;
      transform: translateY(calc(-50% + 24px));
      background: #fff;
      width: 100%;
      flex-direction: column;
      box-shadow: 0 0 29px rgba(0, 0, 0, .27);
      border-radius: 5px;
      display: none;
      padding: 27px 14px 24px 24px;

      &.select__active {
        display: flex;
      }
    }
  }


}

.select {
  &_container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 10px;


    @media screen and (max-width: 660px) {
      max-width: 200px;
    }
  }

  &_title {
    vertical-align: middle;
    height: 100%;
    font-size: 17px;
    font-family: "Ubuntu Regular", sans-serif;
    color: #432671;
    margin: 0;

    @media screen and (max-width: 768px) {
      font-size: 19px;
    }
  }

  &_btn {
    height: 100%;
    width: 18px;

    margin-top: 1px;

    background: url(/img/vacancies/arrow-down_ico.svg) 50% no-repeat;
    background-size: contain;
  }
}

.active {
  color: #432671;
}

.select__active {
  display: flex;
}


