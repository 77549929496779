.section {
	padding-bottom: 70px;
	padding-top: 80px;

	@media screen and (max-width: 600px) {
		padding-top: 0;
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 20px;

}

.item {
	width: calc(100% / 3 - 14px);

	@media screen and (max-width: 800px) {
		width: calc(100% / 2 - 10px);
	}

	@media screen and (max-width: 600px) {
		width: 100%;
	}
}

.item_title {
	background-color: rgb(255, 255, 255);
	width: 100%;
	height: 62px;
	font-size: 18px;
	font-family: "Ubuntu", sans-serif;
	color: rgb(67, 38, 113);
	line-height: 1.2;
	padding: 21.2px 25px 21.2px 40px;
	position: relative;
	display: flex;
	align-items: center;
	z-index: 1;

	&_first {
		padding: 21.2px 25px;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: -20px;
		width: 20px;
		height: 100%;
		align-items: center;
		background-color: rgb(255, 255, 255);
		@media screen and (max-width: 600px) {
			display: none;
		}
	}

	&_last {
		&:before {
			width: 40px;
			right: -30px;
		}
	}
}

.item_title_gray_row {
	position: absolute;
	display: block;
	height: 100%;
	width: 50px;
	right: -54px;
	top: 0;

	@media screen and (max-width: 600px) {
		display: none;
	}

	&_last {
		right: -50px;
	}
}

.item_title_gray_row_top_line {
	top: 2px;
	display: block;
	position: relative;
	right: 18px;
	width: calc(100% + 20px);
	height: 15px;
	background-color: #f6f8f9;
	transform: rotate(45deg);

	&_last {
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			right: -15px;
			width: 100px;
			height: 50px;
			align-items: center;
			background-color: #f6f8f9;
		}
	}
}

.item_title_gray_row_under_line {
	z-index: 1;
	top: 29px;
	display: block;
	position: relative;
	right: 15px;
	width: calc(100% + 20px);
	height: 15px;
	background-color: #f6f8f9;
	transform: rotate(-45deg);

	&_last {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: -15px;
			width: 100px;
			height: 50px;
			align-items: center;
			background-color: #f6f8f9;
		}
	}

}

.desc {
	background-color: rgb(255, 255, 255);
	font-size: 15px;
	font-family: "Roboto", sans-serif;
	color: rgb(0, 12, 18);
	line-height: 1.2;
	padding: 23px 30px;
	z-index: 2;
	position: relative;
}