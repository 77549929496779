.join-us {
	width: 100%;
	height: auto;
	background: linear-gradient(61deg, rgb(67, 38, 113) 30%, rgb(42, 38, 113) 70%);

	&_wrapper {
		margin: 0 auto;
		padding: 55px 0;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		@media screen and (max-width: 910px) {
			flex-direction: column;
		}
	}

	&_text {
		font-size: 20px;
		font-family: "Ubuntu Regular", sans-serif;
		color: #FFF;
		line-height: 1.2;
	}

	&_link {
		padding: 12px 44px;

		background: #FFF;
		border-radius: 40px;

		font-size: 15px;
		color: #432671;
		text-transform: uppercase;
		line-height: 1.2;
		text-decoration: none;

		transition: .3s;
		outline: 0;
		border: 0;
		cursor: pointer;

		&:hover {
			background: #673aaf;
			color: #fff;
		}

		@media screen and (max-width: 700px) {
			padding: 8px 30px;
		}
	}

	&_logo {
		width: 185px;
		height: 40px;

		@media screen and (max-width: 1100px) {
			display: none;
		}
	}
}
