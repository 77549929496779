@mixin position_font {
  position: absolute;
  z-index: 3;
  left: 110px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  line-height: 1.2;
  font-size: 26px;
}

.calculator {
  margin-top: 45px;
  margin-bottom: 105px;
  overflow: visible;

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }

  &_title {
    font-size: 40px;
    font-family: "Ubuntu", sans-serif;
    line-height: 1.2;
    font-weight: 400;
    color: #000;
    margin-bottom: 112px;

    @media screen and (max-width: 950px) {
      margin-bottom: 60px;
      margin-top: 60px;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 600px) {
      margin-top: 15px;
      font-size: 34px;
    }
  }

  &_sub-title {
    font-family: "Ubuntu Medium", sans-serif;
    font-size: 24px;
    line-height: 1.2;
    color: #432671;
    margin-bottom: 40px;

    @media screen and (max-width: 420px) {
      font-size: 20px;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 950px) {
      flex-direction: column;
    }

    &-item {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &_buttons {
      display: flex;
      flex-direction: row;
    }

    &_btn-contact {
      background-color: #432671;
      border-radius: 100px;
      padding: 10px 20px;
      font-family: "Ubuntu", sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      color: #fff;
      text-transform: uppercase;
      transition: .3s;
      border: none;

      &:hover {
        background-color: #909090;
        transition: .3s;
      }

      @media screen and (max-width: 600px) {
        font-size: 13px;
      }
    }

    &_btn-calculate {
      margin-left: 30px;
      padding: 10px 20px;
      border-radius: 100px;
      font-family: "Ubuntu", sans-serif;
      font-weight: 500;
      line-height: 1;
      font-size: 15px;
      text-transform: uppercase;
      border: 1px solid #432671;
      color: #432671;
      transition: .3s;
      background: transparent;
      text-decoration: none;

      &:hover {
        border: 1px solid #909090;
        background-color: #909090;
        color: #FFF;
        transition: .3s;
      }

      @media screen and (max-width: 600px) {
        font-size: 13px;
      }
    }
  }

  &_inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 950px) {
      flex-direction: column;
    }

    &-first {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      margin-bottom: 110px;

      @media screen and (min-width: 950px) {
        min-width: 425px;
      }

      @media screen and (max-width: 950px) {
        min-width: 600px;
        margin: 0 auto;
      }

      @media screen and (max-width: 670px) {
        min-width: 100%;
      }

    }

    &-second {
      min-width: 77px;

      @media screen and (max-width: 950px) {
        padding-top: 90px;
        margin: 0 auto;
      }

      //@media screen and (max-width: 950px) {
      //  padding-top: 40px;
      //}

      @media screen and (max-width: 768px) {
        padding-top: 40px;
      }

    }

    &-third {
      padding-top: 80px;

      @media screen and (max-width: 950px) {
        padding-top: 20px;
        margin: 0 auto;
      }
    }

    &-price {
      font-family: "Ubuntu", sans-serif;
      font-size: 30px;
      line-height: 1.2;
      color: black;
      margin-bottom: 10px;

      @media screen and (max-width: 420px) {
        font-size: 20px;
      }
    }

    &-text {
      font-family: "Ubuntu", sans-serif;
      font-size: 18px;
      line-height: 1.2;
      color: black;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  &_list {
    list-style-type: none;
    padding: 0;

    &-item {
      padding-left: 25px;
      padding-bottom: 0;
      margin-bottom: 0;
      list-style: none;

      font-size: 20px;
      font-family: "Ubuntu", sans-serif;
      line-height: 1.8;
      font-weight: 400;
      color: #000c12;
      position: relative;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }

      &:before {
        position: absolute;
        content: '';
        top: 10px;
        height: 11px;
        width: 15px;
        background: url("/img/sales/check.png") no-repeat top center;
        background-size: contain;
        border: none;
      }
    }
  }
}


.underline {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 105px;
    height: 2px;
    background-color: black;
    left: 0;
    top: 21px;

    @media screen and (max-width: 420px) {
      width: 70px;
      top: 12px;
    }
  }
}

.bold {
  font-weight: bold;
}

.font_size {
  font-size: 16px;

  @media screen and (max-width: 420px) {
    font-size: 14px;
  }
}

.arrow {
  width: 77px;
  height: 37px;

  position: absolute;
  top: 75px;

  @media screen and (max-width: 950px) {
    transform: rotate(90deg);
    top: 120px;
    left: calc(50% - 38px);
  }

  @media screen and (max-width: 768px) {
    width: 57px;
    height: 27px;
    top: 80px;
  }
}

.under_block {
  position: absolute;
  bottom: -95px;
  min-width: 585px;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
    min-width: 100%;
    font-size: 14px;
  }
}

.margin {
  margin: 2px;
}

.italic {
  font-family: "Ubuntu Italic", sans-serif;
  color: #432671;
  font-style: italic;
  margin-bottom: 30px;
}

.margin_bottom {
  margin-bottom: 15px;
}

.item_arrow {
  width: 77px;
  min-height: 80px;

  @media screen and (max-width: 950px) {
    width: 100%;
    min-height: 120px;
  }

  @media screen and (max-width: 950px) {
    min-height: 80px;
  }
}