.section_blog {
	overflow: visible;
}

.section_title {
	margin: 60px 0 55px 0;
	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 400;
	font-size: 55px;
	line-height: 70px;
	text-align: left;
	color: #000;

	@media screen and (max-width: 768px) {
		font-size: 40px;
		margin: 20px 0 15px 0;
	}
}

.first_post {
	padding-bottom: 100px;

	width: 100%;
	display: flex;
	flex-direction: row;

	@media screen and (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
	}
}

.first_post_description {
	width: 100%;

	padding-left: 20px;

	position: relative;

	@media screen and (max-width: 1350px) {
		width: calc(100% - 80px);
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media screen and (max-width: 1024px) {
		margin-top: 20px;
		height: calc(100% + 50px);
		padding-left: 0;
		width: 100%;

	}
}

.first_post_title {
	margin: 0 0 20px 0;
	width: 100%;

	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 400;
	font-size: 40px;
	line-height: 48px;
	color: #000;
	@media screen and (max-width: 1350px) {
		width: 100%;

		margin-top: 0;

		font-size: 30px;
	}

	@media screen and (max-width: 1024px) {
		width: 100%;
	}
}

.first_post_text {
	width: 100%;

	padding-bottom: 30px;
	margin: 0;

	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 400;
	font-size: 22px;
	line-height: 24px;

	@media screen and (max-width: 1150px) {
		font-size: 18px;
	}

	@media screen and (max-width: 1024px) {
		width: 100%;
	}
}

.first_post_link {
	width: auto;

	position: absolute;
	left: 30px;
	//bottom: 120px;

	background-color: #432671;
	border-radius: 100px;
	padding: 10px 20px;
	font-size: 16px;
	line-height: 1;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	transition: .3s;

	@media screen and (max-width: 1350px) {
		bottom: 0;
	}

	@media screen and (max-width: 1024px) {
		right: auto;
		left: auto;
		bottom: -35px;
	}
}

.first_post_link:hover {
	background-color: #909090;
	transition: .3s;
}

.first_img {
	width: 670px;
	height: 445px;

	background-color: #432671;

	object-fit: cover;
	object-position: center;

	@media screen and (max-width: 1350px) and (min-width: 1025px) {
		width: calc(100% - 550px)
	}

	@media screen and (max-width: 1024px) {
		width: 100%;
	}


}

.blogs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
}

.blogs_item_wrapper {
	width: 550px;
	padding-bottom: 70px;
	max-height: 900px;

	&:hover {
		height: calc(100% - 66px);
	}

	@media screen and (max-width: 1140px) {
		width: calc((100% / 2) - 20px);
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		//max-height: 1100px;
	}
}

.blogs_border {
	width: 100%;
	background: #FFF;
}

.blogs_item {
	display: flex;
	flex-direction: column;
	transition: all 0.3s ease-in-out;

	&:hover {
		cursor: pointer;

		.blogs_description {
			transform: scale(0.9);
			transition: all 0.3s ease-in-out;
		}

		.blogs_border {
			transform: translateY(-105px);
			transition: all 0.3s ease-in-out;
			box-shadow: 0 0 15px rgba(0, 0, 0, .27);
		}

		.blogs_link {
			display: block;
			opacity: 1;
			transition: opacity .4s cubic-bezier(1, 0.04, 0.63, 0.42);
		}

		.blogs_item_wrapper {
			height: 100%;
		}
	}

	@media screen and (max-width: 768px) {
		height: auto;
	}
}

.blogs_img {
	width: 550px;
	height: 350px;

	background-color: #432671;

	object-fit: cover;
	object-position: center;

	@media screen and (max-width: 1200px) {
		width: 100%;
		height: 300px;
	}

	@media screen and (max-width: 768px) {
		height: auto;
	}
}

.blogs_description {
	padding: calc(3%) 25px calc(105px - 5%) 25px;
	//padding-top: calc(5%);
	//padding-bottom: calc(105px - 5%);
	background: #ffffff;

	position: relative;

	overflow: hidden;

	transition: all 0.3s ease-in-out;
}

.blogs_title {
	padding-left: 25px;
	margin: 0 0 0 -25px;

	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 400;
	font-size: 30px;
	line-height: 1.2;
	color: #000;
	text-align: left;

	position: relative;

	@media screen and (max-width: 1200px) {
		width: 100%;
	}
}

.blogs_title::before {
	position: absolute;
	content: "";

	left: 0;
	width: 4px;
	height: 100%;
	background-color: #432671;
}

.blogs_text {
	margin: 10px 0 20px 0;

	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	color: #000;

	@media(max-width: 1200px) {
		width: 100%;
	}
}

.blogs_link {
	padding: 10px 17px;

	width: auto;

	opacity: 0;
	position: absolute;
	bottom: calc(45px - 5%);

	border: 1px solid #432671;
	border-radius: 20px;
	text-decoration: none;

	text-transform: uppercase;
	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 500;
	font-size: 18px;
	color: #432671;

	transition: all 0.3s ease-in-out;

	&:hover {
		border: 1px solid #909090 !important;
		background-color: #909090;
		padding: 10px 17px;
		color: #fff;
	}
}

.blogs_border {
	transition: all 0.3s ease-in-out;
}
