.theme{
  background: url('/img/pricing/pricing_bg.png') center center no-repeat;
  background-size: cover!important;

  &_block{
    margin: 165px 0 230px 0;

    @media screen and (max-width: 768px) {
      margin: 80px 0 120px 0;
    }

    @media screen and (max-width: 560px) {
      margin: 40px 0 60px 0;
    }
  }

  &_title{
    font-family: "Ubuntu", sans-serif;
    font-size: 55px;
    line-height: 1.255;
    color: #fff;
    font-weight: 400;
    margin-bottom: 30px;

    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
  }

  &_text{
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    line-height: 1.2;
    color: #fff;
    max-width: 695px;
    font-weight: 300;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
}