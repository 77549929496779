.calculator {
  margin-bottom: 110px;
  &_title {
    margin-top: 75px;

    font-size: 40px;
    font-family: "Ubuntu", sans-serif;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;

    @media screen and (max-width: 600px) {
      font-size: 34px;
    }
  }
}

.select_container {
  display: flex;
  flex-direction: column;
  height: max-content;
  max-height: 227px;
  cursor: default;
  padding: 2px 0;
  @media screen and (max-width: 1024px) {
    padding-bottom: 2px;
  }
}

.filter {

  &_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 25px;

    @media screen and (max-width: 1130px) {
      flex-wrap: wrap;
      align-items: end;
    }

    @media screen and (max-width: 660px) {
      flex-direction: column;
    }
  }

  &_btn {
    background-color: rgb(67, 38, 113);
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    padding: 11.3px 25px;
    border-radius: 200px;
    appearance: none;
    outline: 0;
    border: 0;
    cursor: pointer;
    transition: .3s;

    &:hover {
      background: #909090;
    }

    @media screen and (min-width: 660px) and (max-width: 1130px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 660px) {
      width: 100%;
    }
  }

  &_label {
    border-bottom: 2px solid rgb(227, 228, 229);
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    position: relative;
    cursor: pointer;
    width: 282px;
    z-index: 0;
    padding-bottom: 5px;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 18px;
      right: 0;
      top: 0;
      z-index: 2;
      background: url("/img/vacancies/arrow-down_ico.svg") center no-repeat;
      background-size: contain;

      //@media screen and (max-width: 1024px) {
      //  height: 47px;
      //  transition: .3s;
      //}
    }

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
      z-index: 3;
    }

    &_span {
      font-size: 17px;
      white-space: nowrap;
      font-family: "Ubuntu Regular", sans-serif;
      color: rgb(157, 166, 177);
      line-height: 1.2;
    }

    &_input {
      appearance: none;
      outline: none;
      padding: 0 16px 0 10px;
      height: 100%;
      font-size: 17px;
      font-family: "Ubuntu Regular", sans-serif;
      color: rgb(67, 38, 113);
      line-height: 1.2;
      background: none;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      border: 0;
    }

    &__select {
      position: absolute;
      left: 0;
      z-index: 11;
      top: 0;
      transform: translateY(calc(-50% + 24px));
      background: #fff;
      width: 100%;
      flex-direction: column;
      box-shadow: 0 0 29px rgba(0, 0, 0, .27);
      border-radius: 5px;
      display: none;
      padding: 34px 14px 24px 24px;

      @media screen and (max-width: 1024px) {
        z-index: 9;
        position: relative;
        transform: translateY(0);
        box-shadow: unset;
        background: #fff;
        padding: 40px 14px 24px 0;
        width: 280px;
      }

      &.select__active {
        display: flex;
      }

      input {
        &:checked {
          & + span {
            color: #432671;
            font-weight: bold;
          }
        }
      }

      label {
        position: relative;
        width: max-content;
        display: inline;
        max-width: 100%;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &:before {
          width: 100%;
          height: 100%;
          z-index: 1;
          cursor: pointer;
        }

        &:hover {
          span {
            color: #432671;
            font-weight: bold;
          }
        }

        input {
          height: 20px;
          width: 20px;
          margin: 0 0 -5px 0;
          border: 1px solid #e3e4e5;
          border-radius: 5px;
          outline: 0;
          appearance: none;
          position: relative;
          //transform: translateY(5px);

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            transition: .3s;
            background: url('/img/vacancies/check copy.svg') center no-repeat;
            background-size: 80%;
            width: 100%;
            height: 100%;
            display: none;
          }

          &:checked {
            &:before {
              display: block;
            }

          }
        }

        &.disabled {
          span {
            opacity: .3;
          }
        }
      }

      &_span {
        font-size: 17px;
        font-family: "Ubuntu Regular", sans-serif;
        color: rgb(0, 0, 0);
        line-height: 1.2;
        cursor: pointer;
        padding-left: 5px;
        padding-right: 5px;
        width: calc(100% - 30px);
        user-select: none;
      }

    }
  }
}

.filter_label_active {
  position: relative;

  &:after {
    @media screen and (max-width: 1024px) {
      transform: rotate(180deg);
    }
  }
}


.visible {
  overflow: visible;
  margin-bottom: 50px;
}

.table {
  width: 100%;
  border-collapse: collapse;

  &__head {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__container {
    margin-top: 100px;
    padding-top: 25px;

    &__wrapper {
      width: 100%;
      background: #f6f8f9;
    }
  }

  &__title {
    font-size: 26px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    margin-bottom: 45px;
    
    @media screen and (max-width: 800px) {
      margin-bottom: 0;
    }
  }

  &__btn {
    width: 25px;
    height: 25px;

    object-fit: cover;
    object-position: center;
    background: url('/img/pricing/del_ico.svg') center no-repeat;

    border: none;
    cursor: pointer;

    &:hover {
      background: url('/img/pricing/del_ico_hover.svg') center no-repeat;
      transition: .3s;
    }
  }

  &__row {
    border-bottom: solid 1px rgba(157, 166, 177, .32);

    @media screen and (max-width: 800px) {
      margin-top: 25px;
      height: 290px;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

    }

    @media screen and (max-width: 450px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
    }

    &__total{
      padding-top: 20px;
      @media screen and (max-width: 801px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 72%;
      }

      @media screen and (max-width: 700px) {
        width: 74%;
      }

      @media screen and (max-width: 450px) {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &__cell {
    padding: 25px 0;

    font-size: 22px;
    font-family: "Ubuntu", sans-serif;
    //line-height: 1.2;
    text-align: left;
    font-weight: 400;

    @media screen and (max-width: 801px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 70%;
      padding: 10px 0;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }

    &__total{

      @media screen and (min-width: 801px) {
        margin-top: 20px;
      }
    }

    &__btn {
      text-align: center;

      @media screen and (min-width: 450px) and (max-width: 801px){
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 801px) {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }

  &_head {
    font-size: 18px;
    font-family: "Ubuntu", sans-serif;
    color: #432671;
    line-height: 1.2;
    text-align: left;
    font-weight: 400;

    &__text {
      font-size: 18px;
      font-family: "Ubuntu", sans-serif;
      color: #432671;
      line-height: 1.2;
      text-align: left;
      font-weight: 400;

      @media screen and (min-width: 800px) {
        display: none;
      }

      //@media screen and (max-width: 600px) {
      //  font-size: 16px;
      //}
    }

    &__btn {
      max-width: 40px;
      text-align: center;

      @media screen and (max-width: 801px) {

      }
    }
  }
}

.text {
  &_bold {
    font-size: 24px;
    font-weight: 600;
    margin: 0;

    @media screen and (max-width: 800px) {
      font-size: 20px;
    }
  }

  &_underline {
    position: relative;
    margin: 0;
    font-size: 20px;

    @media screen and (max-width: 900px) {
      font-size: 18px;
    }

    &:after {
      position: absolute;
      content: '';
      width: 56px;
      height: 2px;
      background-color: black;
      left: 0;
      top: 10px;

      @media screen and (max-width: 900px) {
        width: 51px;
        height: 1px;
      }
    }
  }

  &__total {
    font-size: 18px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    vertical-align: top;

    @media screen and (min-width: 801px) {
      padding-top: 20px;
    }

    @media screen and (max-width: 900px) {
      font-size: 16px;
    }

    @media screen and (max-width: 750px) {
      width: calc(55% + 5px);
    }

    @media screen and (max-width: 400px) {
      width: auto;
    }

    &__result {
      font-size: 28px;
      font-family: "Ubuntu", sans-serif;
      font-weight: 500;
      margin: 0;

      @media screen and (max-width: 900px) {
        font-size: 26px;
      }

      //@media screen and (max-width: 768px) {
      //  font-size: 24px;
      //}
    }

    &__text {
      font-size: 18px;
      font-family: "Ubuntu", sans-serif;
      font-weight: 400;
      margin: 0;

      @media screen and (max-width: 900px) {
        font-size: 16px;
      }

      //@media screen and (max-width: 768px) {
      //  font-size: 14px;
      //}
    }
  }

  &__result {
    font-size: 22px;
    font-family: "Ubuntu", sans-serif;
    text-align: left;
    font-weight: 400;

    &__bold {
      font-family: "Ubuntu", sans-serif;
      text-align: left;
      font-weight: 500;
      font-size: 24px;
      margin: 0;

      @media screen and (max-width: 900px) {
        font-size: 20px;
      }

      //@media screen and (max-width: 768px) {
      //  font-size: 18px;
      //}
    }

    @media screen and (max-width: 900px) {
      font-size: 20px;
    }

    //@media screen and (max-width: 768px) {
    //  font-size: 18px;
    //}
  }
}

.pb_0 {
  padding-bottom: 0;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 75px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }

  &_list {
    list-style-type: none;
    margin-top: 10px;
    padding: 0;

    &__item{
      padding-left: 25px;
      padding-bottom: 0;
      margin-bottom: 0;
      list-style: none;

      font-size: 18px;
      font-family: "Ubuntu", sans-serif;
      line-height: 1.667;
      color: #000c12;
      position: relative;
      text-align: left;
      font-weight: 400;

      @media screen and (max-width: 900px) {
        font-size: 16px;
      }

      //@media screen and (max-width: 768px) {
      //  font-size: 14px;
      //}

      &:before {
        position: absolute;
        content: '';
        top: 10px;
        height: 11px;
        width: 15px;
        background: url("/img/sales/check.png") no-repeat top center;
        background-size: contain;
        border: none;
      }
    }
  }

  &_img {
    width: 495px;
    height: 331px;
    object-fit: cover;
    object-position: center;

    @media screen and (min-width: 1000px) and (max-width: 1200px){
      width: calc(100% - 650px);
      height: auto;
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &_text {
    line-height: 1.2;
    color: black;
    font-size: 16px;
    font-family: "Ubuntu", sans-serif;
    margin: 0;
    font-weight: 400;

    @media screen and (max-width: 900px) {
      font-size: 14px;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }

    //&__margin{
    //  margin-top: 10px;
    //}

    &__cursive{
      margin-top: 5px;
      font-size: 16px;
      font-family: "Ubuntu", sans-serif;
      line-height: 1.625;
      color: #432671;
      font-style: italic;
      margin-bottom: 20px;

      @media screen and (max-width: 900px) {
        font-size: 14px;
      }

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }

  &_btn{
    width: 134px;
    background-color: #432671;
    border-radius: 100px;
    padding: 12px 20px;
    font-family: "Ubuntu", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
    transition: .3s;
    cursor: pointer;
    border: none;

    @media screen and (max-width: 768px) {
      font-size: 13px;
      width: 119px;
    }

    &:hover{
      background-color: #909090;
      transition: .3s;
    }
  }
}

.disabled{
  background-color: #909090;
  cursor: auto;
}

.compare{
  background-color: rgb(246, 248, 249);
  margin-top: 60px;

  @media screen and (max-width: 900px) {
    margin-top: 40px;

  }

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }

  &_text{
    font-size: 22px;
    font-weight: 400;
    font-family: "Ubuntu", sans-serif;
    text-align: center;
    padding: 85px 0;
    margin: 0;

    @media screen and (max-width: 900px) {
      font-size: 20px;
      padding: 60px 0;
    }

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
}