.first_post {
	padding-bottom: 100px;

	width: 100%;
	display: flex;
	flex-direction: row;

	@media screen and (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
	}
}

.first_post_description {
	width: 100%;

	padding-left: 20px;

	position: relative;

	@media screen and (max-width: 1350px) {
		width: calc(100% - 80px);
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media screen and (max-width: 1024px) {
		margin-top: 20px;
		height: calc(100% + 50px);
		padding-left: 0;
		width: 100%;

	}
}

.first_post_title {
	margin: 0 0 20px 0;
	width: 100%;

	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 400;
	font-size: 40px;
	line-height: 48px;
	color: #000;
	@media screen and (max-width: 1350px) {
		margin-top: 0;
		font-size: 30px;
	}

	@media screen and (max-width: 800px) {
		line-height: 1.2;
	}

	@media screen and (max-width: 768px) {
		font-size: 26px;
		margin: 0 0 10px 0;
	}
}

.first_post_text {
	width: 100%;

	padding-bottom: 30px;
	margin: 0;

	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 400;
	font-size: 22px;
	line-height: 24px;

	@media screen and (max-width: 1150px) {
		font-size: 18px;
	}

	@media screen and (max-width: 1024px) {
		width: 100%;
	}
}

.first_post_link {
	width: auto;

	position: absolute;
	left: 30px;
	//bottom: 120px;

	background-color: #432671;
	border-radius: 100px;
	padding: 10px 20px;
	font-size: 16px;
	line-height: 1;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	transition: .3s;

	@media screen and (max-width: 1350px) {
		bottom: 0;
	}

	@media screen and (max-width: 1024px) {
		right: auto;
		left: auto;
		bottom: -35px;
	}
}

.first_post_link:hover {
	background-color: #909090;
	transition: .3s;
}

.first_img {
	width: 670px;
	height: 445px;

	background-color: #432671;

	object-fit: cover;
	object-position: center;

	@media screen and (max-width: 1350px) and (min-width: 1025px) {
		width: calc(100% - 550px)
	}

	@media screen and (max-width: 1024px) {
		width: calc(100% + 40px);
		margin-left: -20px;
		margin-right: -20px;
	}
	@media screen and (max-width: 500px) {
		max-height: 250px;
	}
}

.blogs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
}

.blogs_item_wrapper {
	width: 550px;
	padding-bottom: 70px;
	max-height: 900px;

	@media screen and (max-width: 600px) {
		position: relative;
	}

	&:hover {
		height: calc(100% - 66px);
	}

	@media screen and (max-width: 1140px) {
		width: calc((100% / 2) - 20px);
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		//z-index: 0;
		//max-height: 1100px;
	}

	@media screen and (max-width: 580px) {
		padding-bottom: 0;
	}

	@media screen and (max-width: 580px) {
		margin-bottom: 20px;
	}
}

.blogs_border {
	width: 100%;
	background: #FFF;
}

.blogs_item {
	display: flex;
	flex-direction: column;
	transition: all 0.3s ease-in-out;

	@media screen and (min-width: 600px) {
		&:hover {
			cursor: pointer;

			.blogs_description {
				transform: scale(0.9);
				transition: all 0.3s ease-in-out;
			}

			.blogs_border {
				transform: translateY(-105px);
				transition: all 0.3s ease-in-out;
				box-shadow: 0 0 15px rgba(0, 0, 0, .27);
			}

			.blogs_link {
				display: block;
				opacity: 1;
				transition: opacity .4s cubic-bezier(1, 0.04, 0.63, 0.42);
			}

			.blogs_item_wrapper {
				height: 100%;
			}
		}
	}

	@media screen and (max-width: 768px) {
		height: auto;
	}
}

.blogs_img {
	width: 550px;
	height: 350px;

	background-color: #432671;

	object-fit: cover;
	object-position: center;

	@media screen and (max-width: 1200px) {
		width: 100%;
		height: 300px;
	}

	@media screen and (max-width: 768px) {
		height: auto;
	}
}

.blogs_description {
	padding: calc(3%) 25px calc(105px - 5%) 25px;
	//padding-top: calc(5%);
	//padding-bottom: calc(105px - 5%);
	background: #ffffff;
	transition: all 0.3s ease-in-out;

	@media screen and (min-width: 600px) {
		position: relative;
		overflow: hidden;
	}

	@media screen and (max-width: 768px) {
		padding-bottom: 0;
	}

}

.blogs_title {
	padding-left: 25px;
	margin: 0 0 0 -25px;

	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 400;
	font-size: 30px;
	line-height: 1.2;
	color: #000;
	text-align: left;

	position: relative;

	@media screen and (max-width: 1200px) {
		width: 100%;
	}

	@media screen and (max-width: 768px) {
		font-size: 23px;
	}
}

.blogs_title::before {
	position: absolute;
	content: "";

	left: 0;
	width: 4px;
	height: 100%;
	background-color: #432671;
}

.blogs_text {
	margin: 10px 0 20px 0;

	font-family: "Ubuntu Regular", sans-serif;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	color: #000;

	@media(max-width: 1200px) {
		width: 100%;
	}
}

.blogs_link {
	opacity: 0;
	bottom: calc(45px - 5%);
	position: absolute;
	width: auto;
	cursor: pointer;
}

.blogs_link_a {
	padding: 11px 19px;
	border: 1px solid #432671;
	text-decoration: none;
	text-transform: uppercase;
	font-family: "Ubuntu", sans-serif;
	font-weight: 500;
	font-size: 18px;
	color: #432671;
	transition: 0.3s;
	display: block;

	@media screen and (min-width: 601px) {
		&:hover {
			border: 1px solid #909090;
			background-color: #909090;
			color: #fff;
		}
		border-radius: 40px;
	}

	@media screen and (max-width: 600px) {
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

}

//@media screen and (max-width: 768px) {
//  content: none;
//  all: unset;
//
//  &:after{
//    position: absolute;
//    content: '';
//    left: 0;
//    top: 0;
//    z-index: 55;
//    width: 100%;
//    height: 100%;
//    background: #432671;
//    cursor: pointer;
//  }
//}


.blogs_border {
	transition: all 0.3s ease-in-out;
}