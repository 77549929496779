.container {
  width: 100%;
}

.title {
  font-size: 40px;
  font-family: "Ubuntu", sans-serif;
  color: rgb(0, 0, 0);
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
  margin-top: 67px;
  margin-bottom: 72px;

  @media screen and (max-width: 600px) {
    font-size: 2em;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 35px;
  }
}

.top_img_section__survey {
  background: url("../../img/survey/bg_surv.png") center no-repeat;
  background-size: cover;
}