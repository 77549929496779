.modal_get_in_touch {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal_get_in_touch__content {
	position: relative;
	width: 100%;
	max-width: 1270px;
	background-color: rgb(246, 248, 249);
	box-shadow: 3.632px 7.128px 10.56px 0.44px rgba(10, 6, 66, 0.3);
	border-width: 10px;
	border-color: rgb(231, 235, 238);
	padding: 20px;
	border-radius: 5px;
	border-style: solid;
	overflow: auto;
	max-height: 100%;
	transform: translate3d(-100vw, 0px, 0px);
	transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
	opacity: 0;

	&_active {
		box-shadow: rgba(0, 0, 0, 0.3) 0 2px 4px;
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.modal_background {
	position: fixed;
	top: 0;
	background-color: rgba(0, 0, 0, 0);
	backdrop-filter: blur(15px);
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10001;
	transition: .3s;

	&.active {
		background-color: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(1px);
	}
}