.feedback {
	padding: 120px 0 95px 0;

	@media screen and (max-width: 768px) {
		padding: 70px 0;
	}

	&_title {
		max-width: 780px;
		margin-bottom: 120px;
		margin-top: 0;

		font-size: 40px;
		font-family: "Ubuntu Regular", sans-serif;
		color: #000;
		line-height: 1.2;
		font-weight: normal;

		@media screen and (max-width: 600px) {
			font-size: 34px;
		}
	}

	&_container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		//height: 246px;
	}

	&_item {
		opacity: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 30px;

		&-active {
			opacity: 1;
			//transition: all ease-in-out 0.3s;
		}

		@media screen and (max-width: 1026px) {
			width: 50%;
		}

		@media screen and (max-width: 767px) {
			width: 130px;
		}
	}

	&_img {
		width: 40px;
		height: 40px;

		background-repeat: no-repeat;
		background-position: center;
	}

	&_timer {
		margin: 30px 0 0 0;

		font-size: 45px;
		font-family: "Ubuntu", sans-serif;
		color: #000;
		line-height: 1.2;

		@media screen and (max-width: 768px) {
			font-size: 30px;
		}
	}

	&_text {
		max-width: 170px;
		font-size: 22px;
		font-family: "Ubuntu", sans-serif;
		color: rgb(157, 166, 177);
		text-align: center;
		margin-top: 11px;

		@media screen and (max-width: 768px) {
			font-size: 18px;
		}
	}
}



