.clearOptions {
	position: absolute;
	right: 5px;
	top: 5px;
	padding: 5px;
	cursor: pointer;
	user-select: none;
	transition: .3s;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	border-radius: 100px;

	&:hover {
		color: #432671;
	}
}


.select_container {
	display: flex;
	flex-direction: column;
	height: max-content;
	max-height: 227px;
	cursor: default;
	padding: 2px 0;
	@media screen and (max-width: 1024px) {
		padding-bottom: 2px;
	}
}

.filter {
	display: flex;
	z-index: 1;

	@media screen and (max-width: 1024px) {
		z-index: 1000;
		position: fixed;
		width: 320px;
		top: 80px;
		left: -100vw;
		height: calc(100% - 80px);
	}

	& > .filter_background {
		@media screen and (max-width: 1024px) {
			content: '';
			position: absolute;
			left: 0;
			z-index: -1;
			top: 0;
			width: 100vw;
			height: 100vh;
			background: rgba(0, 0, 0, .3);
			opacity: 0;
			transition: .3s;
		}
	}


	&_form {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;


		&_label {
			border-bottom: 2px solid rgb(227, 228, 229);;
			display: flex;
			flex-direction: row;
			justify-content: stretch;
			align-items: center;
			position: relative;
			cursor: pointer;
			width: 282px;
			z-index: 0;
			padding-bottom: 5px;

			@media screen and (max-width: 1024px) {
				flex-direction: column;
				align-items: flex-start;
				margin-bottom: 20px;
				cursor: none;
				height: unset;
			}

			&__select {
				position: absolute;
				left: 0;
				z-index: 11;
				top: 0;
				transform: translateY(calc(-50% + 24px));
				background: #fff;
				width: 100%;
				flex-direction: column;
				box-shadow: 0 0 29px rgba(0, 0, 0, .27);
				border-radius: 5px;
				display: none;
				padding: 34px 14px 24px 24px;

				@media screen and (max-width: 1024px) {
					z-index: 9;
					position: relative;
					transform: translateY(0);
					box-shadow: unset;
					background: #fff;
					padding: 40px 14px 24px 0;
					width: 280px;
				}

				&.select__active {
					display: flex;
				}

				input {
					&:checked {
						& + span {
							color: #432671;
							font-weight: bold;
						}
					}
				}

				label {
					position: relative;
					width: max-content;
					display: inline;
					max-width: 100%;

					&:not(:last-child) {
						margin-bottom: 20px;
					}

					&:before {
						width: 100%;
						height: 100%;
						z-index: 1;
						cursor: pointer;
					}

					&:hover {
						span {
							color: #432671;
							font-weight: bold;
						}
					}

					input {
						height: 20px;
						width: 20px;
						margin: 0 0 -5px 0;
						border: 1px solid #e3e4e5;
						border-radius: 5px;
						outline: 0;
						appearance: none;
						position: relative;
						//transform: translateY(5px);

						&:before {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							transition: .3s;
							background: url('/img/vacancies/check copy.svg') center no-repeat;
							background-size: 80%;
							width: 100%;
							height: 100%;
							display: none;
						}

						&:checked {
							&:before {
								display: block;
							}

						}
					}

					&.disabled {
						span {
							opacity: .3;
						}
					}
				}

				&_span {
					font-size: 17px;
					font-family: "Ubuntu Regular", sans-serif;
					color: rgb(0, 0, 0);
					line-height: 1.2;
					cursor: pointer;
					padding-left: 5px;
					padding-right: 5px;
					width: calc(100% - 30px);
					user-select: none;
				}

			}

			&:after {
				content: '';
				position: absolute;
				height: 100%;
				width: 18px;
				right: 0;
				top: 0;
				z-index: 2;
				background: url("/img/vacancies/arrow-down_ico.svg") center no-repeat;
				background-size: contain;

				@media screen and (max-width: 1024px) {
					height: 47px;
					transition: .3s;
				}
			}

			&:before {
				content: '';
				position: absolute;
				height: 100%;
				width: 100%;
				left: 0;
				top: 0;
				cursor: pointer;
				z-index: 3;
			}

			&_span {
				font-size: 17px;
				white-space: nowrap;
				font-family: "Ubuntu Regular", sans-serif;
				color: rgb(157, 166, 177);
				line-height: 1.2;

				@media screen and (max-width: 1024px) {
					//height: 50px;
					//flex-direction: column;
				}
			}

			&_input {
				appearance: none;
				outline: none;
				padding: 0 16px 0 10px;
				height: 100%;
				font-size: 17px;
				font-family: "Ubuntu Regular", sans-serif;
				color: rgb(67, 38, 113);
				line-height: 1.2;
				background: none;
				text-overflow: ellipsis;
				overflow: hidden;
				margin: 0;
				border: 0;

				@media screen and (max-width: 1024px) {
					width: calc(100% - 26px);
					padding-left: 0;
				}

				&__button {
					background-color: rgb(67, 38, 113);
					font-size: 15px;
					color: rgb(255, 255, 255);
					text-transform: uppercase;
					padding: 11.3px 25px;
					border-radius: 200px;
					appearance: none;
					outline: 0;
					border: 0;
					cursor: pointer;
					transition: .3s;

					@media screen and (max-width: 1024px) {
						margin-top: 20px;
					}

					&:hover {
						background: #909090;
					}
				}
			}

		}
	}
}

.filter_form_label_active {
	position: relative;

	&:after {
		@media screen and (max-width: 1024px) {
			transform: rotate(180deg);
		}
	}
}

.filter_container {
	@media screen and (max-width: 1024px) {
		z-index: 1;
		display: flex;
		flex-direction: column;
		background: #fff;
		padding-top: 50px;
		padding-bottom: 50px;
		transition: .3s;
		transform: translateX(-100vw);
	}

}

.filter_mb_active {
	left: 0;

	& > .filter_background {
		opacity: 1;
	}

	& > .filter_container {
		transform: translateX(0);
	}
}

.mobile_filter_button {
	font-family: "Ubuntu", sans-serif;
	font-weight: 500;
	font-size: 1em;
	color: #fff;
	background-color: #432671;
	border-radius: 200px;
	border: none;
	padding: 11px 25px 11px 55px;
	cursor: pointer;
	text-align: center;
	line-height: 1;
	text-decoration: none;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: 20px;
	text-transform: uppercase;

	@media screen and (max-width: 1024px) {
		width: 100%;
	}

	&:before {
		content: '';
		position: absolute;
		left: calc(50% - 50px);
		top: 0;
		background: url("/img/vacancies/filter.svg") center no-repeat;
		height: 100%;
		width: 30px;
		background-size: auto 25px;
	}
}

.w100 {
	width: 100%;
	overflow-y: unset;
	@media screen and (max-width: 1024px) {
		overflow-y: auto;
	}
}

.section {
	overflow: visible;
}
