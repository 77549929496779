.form_col_3 {
	width: calc(33% - 20px);
	@media screen and (max-width: 1024px) {
		min-width: 250px;
	}
}

.form_col_3_1024 {
	@media screen and (max-width: 1024px) {
		width: 100%;
	}
}

.form_col_3_800 {
	@media screen and (max-width: 800px) {
		width: 100%;
	}
}