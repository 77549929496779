.principals {
  margin-bottom: 115px;

  &_title {
    margin-top: 51px;
    margin-bottom: 43px;
    font-size: 40px;
    font-family: "Ubuntu", sans-serif;
    line-height: 1.2;
    font-weight: normal;
    color: #000;
    text-align: center;

    @media screen and (max-width: 600px) {
      font-size: 32px;
    }
  }

  &_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &_group{
    width: 350px;
    margin-bottom: 20px;

    &:last-child{
      margin-bottom: 0;
    }

    @media screen and (max-width: 760px){
      width: 100%;
    }
    &-title {
      font-family: "Ubuntu Medium", sans-serif;
      font-size: 20px;
      line-height: 1.2;
      color: #432671;
      margin-bottom: 27px;
    }

    &-text{
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 1.4;
      margin-bottom: 0;
    }

    &-list{
      margin-top: 5px;
    }

    &-item{
      margin-bottom: 5px;

      font-size: 15px;
      font-family: "Roboto Regular", sans-serif;
      color: rgb(0, 12, 18);
      line-height: 1.4;
    }
  }
}

.mb_0{
  margin-bottom: 0;
}



