.get-price {
  background: rgb(246, 248, 249);

  &_content {
    padding: 107px 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 770px) {
      justify-content: center;
    }
  }

  &_img {
    width: 110px;
    height: 110px;
    margin-left: 140px;
    margin-top: 22px;

    @media screen and (max-width: 980px) {
      margin-left: 0;
    }

    @media screen and (max-width: 770px) {
      display: none;
    }
  }

  &_form {
    display: flex;
    flex-direction: column;
    width: 585px;
    position: relative;
    margin-right: 200px;
    position: relative;

    @media screen and (max-width: 1200px) {
      margin-right: 0;
    }
  }

  &_title {
    margin: 5px 0 52px 0;
    font-size: 22px;
    font-family: "Ubuntu", sans-serif;
    color: rgb(0, 0, 0);
    line-height: 1.182;
    font-weight: 400;
  }

  &_input {
    background: transparent;
    position: relative;
    margin-bottom: 35px;

    outline: 0;
    border-width: 0 0 2px;
    border-color: #687482;

    padding-bottom: 12px;
    padding-top: 12px;

    &::placeholder {
      font-size: 16px;
      font-family: "Roboto";
      color: #939799;
      line-height: 1.2;
    }

    &:focus {
      border-color: #432671;
    }


    &:focus-visible {
      borer: none;
    }
  }

  &_error {
    position: absolute;
    bottom: 35px;
    font-size: 16px;
    font-family: "Ubuntu";
    color: red;
    line-height: 1.625;
  }

  &_note {
    font-size: 16px;
    font-family: "Ubuntu";
    color: black;
    font-style: italic;
    line-height: 1.625;
  }

  &_successful {
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    margin: 0;
    color: #58b554;
    align-self: center;
    position: absolute;
    left: 0;
    bottom: -60px;

    font-family: Ubuntu, sans-serif;
    font-weight: 400;
    font-size: 18px;


    &-show {
      visibility: visible;
      opacity: 1;
      transition: 1s;
    }
  }

  &_error-message {
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    margin: 0;
    color: red;
    align-self: center;
    position: absolute;
    left: 0;
    bottom: -60px;

    font-family: Ubuntu, sans-serif;
    font-weight: 400;
    font-size: 18px;

    &-show {
      visibility: visible;
      opacity: 1;
      transition: 1s;
    }
  }

  &_btn {
    width: 134px;
    background-color: #432671;
    border-radius: 100px;
    padding: 12px 20px;
    font-family: "Ubuntu", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
    transition: .3s;
    cursor: pointer;
    border: none;

    position: absolute;
    right: 0;
    bottom: 0;

    &:hover {
      background-color: #909090;
      transition: .3s;
    }

    &-disabled {
      background-color: #909090;
      cursor: auto;
    }

    @media screen and (max-width: 768px) {
      bottom: -45px;
    }
  }
}