@import '../../style/variables';

.article-of-blog{
  &_header{
    position: relative;
    background-size: cover !important;

    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #687482;
      opacity: 0.75;
    }

    &_text_block{
      color: #fff;
      text-align: left;
      z-index: 100;
      max-width: 1200px;
      padding: 147px 20px 190px 20px;
      position: relative;
      margin: 0 auto;
    }

    &_category{
      background-color: #432671;
      padding: 12px 14px;
      margin-top: 135px;

      font-family: "Roboto Regular", sans-serif;
      font-size: 20px;
      text-transform: uppercase;

      @media screen and (max-width: 768px){
        font-size: 16px;
      }
    }

    &_title{
      font-family: "Ubuntu", sans-serif;
      font-size: 55px;
      line-height: 1.255;
      font-weight: 400;

      @media screen and (max-width: 650px) {
        font-size: 30px;
      }
    }

    &_date{
      font-family: "Ubuntu", sans-serif;
      font-size: 22px;
      font-weight: 400;

      @media screen and (max-width: 768px){
        font-size: 18px;
      }
    }
  }
}

.section_article-of-blog {

  max-width: 1160px;
  width: 95vw;
  margin: 0 auto;
  padding-bottom: 70px;
  position: relative;
  //margin-bottom: 70px;

  li {
    font-family: $robotoRegular;
  }

  @media screen and (max-width: $tablet-landscape-upper-boundary) {

    padding: 0 20px;
    width: 100%;
  }

  .article-of-blog_content {

    max-width: 765px;
    width: 100%;
    float: right;

    @media screen and (max-width: 1200px) {
      max-width: 100%;
    }
    //margin-top: 45px;

    ol {

      padding-left: 20px;

      li {

        padding-bottom: 15px;

      }

    }

    ul {

      padding-left: 20px;

      li{

        padding-bottom: 15px;
        margin-bottom: 0;
      }
    }

    h3 {

      font-size: 40px;
      font-family: $ubuntu;
      color: rgb(67, 38, 113);
      font-weight: 400;
      padding-top: 50px;
      padding-bottom: 20px;
      margin: unset;
      //margin-top: 50px;
      //margin-bottom: 20px;
      line-height: 1.5;

      @media screen and(max-width: $tablet-landscape-upper-boundary) {

        line-height: 1.2;
        font-size: 30px;

      }

    }

    h4 {

      font-size: 30px;
      color: rgb(0, 12, 18);
      font-weight: 100;
      margin-bottom: 0;

      @media screen and(max-width: $tablet-landscape-upper-boundary) {

        font-size: 25px;

      }

    }

    p {

      font-size: 16px;
      font-family: $robotoRegular;
      color: rgb(0, 12, 18);
      line-height: 1.5;
      font-weight: 500;

      &:last-child{
        margin-bottom: 0;
        padding-bottom: 16px;
      }

    }

    @media screen and (max-width: $tablet-landscape-upper-boundary) {

      ul {

        padding-left: 20px;

        li{

        }
      }

    }

    img {

      width: 100%;
      height: auto;
      //margin: 16px 0;
      margin-top: 5px;

    }
  }
}