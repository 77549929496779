.zIndex2 {
	z-index: 2;
}


.hidden {
	opacity: 0 !important;
	position: absolute !important;
	left: 0 !important;
	top: 0 !important;
	height: 0 !important;
	width: 0 !important;
}

.h100 {
	height: 100%;
}

.w100 {
	width: 100%;
}

.filter_form_label {
	border-color: #687482;
	@media screen and (max-width: 1024px) {
		flex-direction: row;
		align-items: center;
	}

}

.filter_form_label__select {
	@media screen and (max-width: 1024px) {
		position: absolute;
		width: 100%;
	}
}

.filter_form_label_input {
	@media screen and (max-width: 1024px) {
		padding-left: 8px;
	}
}

.filter_form_label_span {
	font-size: 1em;
	color: #727373;
}


.feedback_form__label__error {
	color: #dd4c5d !important;
	border-color: #dd4c5d !important;

	.feedback_form__error_msg {
		display: block !important;
	}

	.filter_form_label_span, .filter_form_label_input {
		color: #dd4c5d !important;
	}

	&:after {
		background: url("/img/vacancies/arrow-down_ico_error.svg") center no-repeat;
	}

}