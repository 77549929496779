@import '../../../style/variables';

.article-of-blog_control {

  position: absolute;
  left: 0;
  top: 174px;
  //transition: 0.4s;

  &.active_fixed {

    @media screen and (min-width: $tablet-landscape-upper-boundary + 1) {

      position: fixed;
      left: unset;
      top: 100px;

    }

  }

  &.active_absolute {

    @media screen and (min-width: $tablet-landscape-upper-boundary + 1) {

      position: absolute;
      left: 0 !important;
      bottom: 0;
      top: auto;

    }

  }

  @media screen and (max-width: $tablet-landscape-upper-boundary) {

    background: #432671;
    width: 100%;
    max-width: 320px;
    top: 80px;
    left: -320px !important;
    position: fixed;
    height: 100%;
    color: #fff;
    padding: 0 15px;
    z-index: 101;
    transition: 0.8s;

  }

  &::before {

    transition: opacity 0.8s;
    top: 0;
    opacity: 0;
    position: absolute;
    z-index: -2;
    height: 100vh;
    width: 500vw;
    content: '';
    background: rgba(5, 5, 5, 0.3);
    right: 0;
    left: auto;

  }

  &.mobile-active {

    left: 0 !important;

    &::before {

      opacity: 1;
      left: 0;
      right: auto;

    }

    &::after {

      content: '';
      position: absolute;
      z-index: -1;
      background: #432671;
      right: 0;
      top: 0;
      opacity: 1;
      height: 100vh;
      width: 400vw;
    }

  }

  &-title {

    font-family: $ubuntuRegular;
    font-size: 26px;
    color: rgb(0, 12, 18);
    line-height: 2.654;

    @media screen and (max-width: $tablet-landscape-upper-boundary) {

      color: #fff;

    }

  }

  a {
    display: block;
    width: 223px;
    padding: 15px 0 15px 33px;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    font-family: "Ubuntu", sans-serif;
    line-height: 1.2;
    color: #b3b9c2;
    transition: 0.5s;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;



    @media screen and (min-width: 1201px){
      &:hover{
        color: rgb(67, 38, 113);
      }
    }

    &::selection {

      background: none;

    }

    @media screen and (max-width: $tablet-landscape-upper-boundary) {

      padding-left: 0;
      //cursor: none;

    }

    &.active {

      color: rgb(67, 38, 113);
      @media screen and (max-width: $tablet-landscape-upper-boundary) {

        color: #fff;

      }

      &::before {

        @media screen and (min-width: $tablet-landscape-upper-boundary+1) {

          content: '';
          position: absolute;
          height: 100%;
          width: 20px;
          background: url("../../../img/article/arrow.png") no-repeat left;
          background-size: contain;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;

        }

      }

    }


  }

  &-list {

    @media screen and (max-height: 660px) {

      overflow-y: auto;
      max-height: calc(100vh - 150px);

    }

  }

}

.article-of-blog_control-nav-ico {
  position: fixed;
  left: 0;
  //padding: 20px;
  height: 45px;
  width: 45px;
  top: 25vh;
  background: #9da6b1;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 1s;

  @media screen and (min-width: $tablet-landscape-upper-boundary + 1) {
    display: none;
  }

  &.hide{
    display: none;

  }

  img {

    width: 27px;

  }

  .index{
    z-index: -1;
  }

}