
// -- BREAK POINTS ---

$phone-upper-boundary: 600px;
$tablet-portrait-upper-boundary: 900px;
$tablet-landscape-upper-boundary: 1200px;
$desktop-upper-boundary: 1800px;



$width-container: 1200px;



// -- FONTS ---

$ubuntu: 'Ubuntu', sans-serif;
$ubuntuMedium: 'Ubuntu Medium', sans-serif;
$ubuntuRegular: 'Ubuntu Regular', sans-serif;

$roboto: 'Roboto', sans-serif;
$robotoLight: 'Roboto Light', sans-serif;
$robotoRegular: 'Roboto Regular', sans-serif;

// -- COLORS ---

$white: #fff;
$white-border: #e3e4e5;
$section-bg: #f6f8f9;
$grey: #687482;
$light-grey: #4d5153;
$dark-gray: #4d5054;
$services-black-layout: #37404b;
$dart-black: #000;
$black: #000c12;


$white-header-bottom-border: #dfdedd;


$btn: #432671;
$btn-hover: #909090;
$error:  #dd4c5d;


$success: #58b554;



