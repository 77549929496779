@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=cyrillic');

@font-face {
  font-family: "Roboto Regular";
  src: local('Roboto'), url('https://fonts.googleapis.com/css?family=Roboto:500&subset=cyrillic') format('truetype');
}

@font-face {
  font-family: "Roboto Medium";
  src: local('Roboto'), url('https://fonts.googleapis.com/css?family=Roboto:700&subset=cyrillic') format('truetype');
}

@font-face {
  font-family: "Roboto Light";
  src: local('Roboto'), url('https://fonts.googleapis.com/css?family=Roboto:300&subset=cyrillic') format('truetype');
}


@font-face {
  font-family: "Ubuntu Regular";
  src: local('Ubuntu'), url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&subset=cyrillic') format('truetype');
}

@font-face {
  font-family: "Ubuntu Medium";
  src: local('Ubuntu'), url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&subset=cyrillic') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: "Ubuntu Bold";
  src: local('Ubuntu'), url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&subset=cyrillic') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Ubuntu Italic';
  src: local('Ubuntu'), url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&subset=cyrillic') format('truetype');
  font-weight: normal;
  font-style: italic;
}


* {
  box-sizing: border-box;
}

html {
  //scroll-behavior: smooth;
  &.menu-open {
    overflow: hidden;
  }
}

.menu-open {
  body {
    overflow: hidden;
  }
}

html, body {
  font-size: 16px;
}

body {
  font-family: $ubuntu;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.section-title {
  position: relative;
  font-family: $ubuntu;
  font-weight: 400;
  font-size: 2.5em;
  color: $dart-black;
  text-align: center;
  margin-top: 85px;
  margin-bottom: 70px;

  &--left {
    text-align: left;
  }

  &--large {
    font-size: 3.4375em;
  }

  &--purple {
    color: $btn;
  }

  @media screen and (max-width: $phone-upper-boundary) {
    font-size: 2em;
  }
}

.h3-title {
  font-family: $ubuntu;
  font-weight: 500;
  font-size: 1.875em;
  text-align: left;

  &--upper {
    text-transform: uppercase;
  }

  &--purple {
    color: $btn;
  }

  &--center {
    text-align: center;
  }

}

.h4-title {
  font-size: 1.25em;
  font-weight: 500;
  font-family: $ubuntu;
  text-align: left;
  margin-top: 0;
  margin-bottom: 25px;

  &--purple {
    color: $btn;
  }

  &--center {
    text-align: center;
  }
}

.h5-title {
  font-size: 1.125em;
  font-weight: 500;
  font-family: $ubuntu;
  text-align: left;
  margin-top: 0;
  margin-bottom: 25px;

  &--purple {
    color: $btn;
  }

  &--center {
    text-align: center;
  }

  &--small {
    font-size: 1em;
  }
}

section {
  overflow: hidden;
}

.section-bg {
  background: $section-bg;
  //overflow: auto;
  //max-height: calc(100vh - 20px)
}

.pt-85 {
  padding-top: 85px;
}

.pt-40 {
  padding-top: 40px;
}

.bottom-padding-70 {
  padding-bottom: 70px;
}

.bottom-padding-150 {
  padding-bottom: 150px;
}

.mb-100 {
  margin-bottom: 100px;
}

.w-auto {
  width: auto !important;
}

.container {
  max-width: $width-container;
  padding: 0 20px;
  margin: 0 auto;
}

.btn {
  font-family: $ubuntu;
  font-weight: 500;
  font-size: 1em;
  color: $white;
  background-color: $btn;
  border-radius: 20px;
  border: none;
  padding: 0 18px;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  //display: inline-block;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--160w {
    width: 160px;
  }


  &:hover {
    background-color: $btn-hover;
    transition: .3s;
  }

  &.btn--upper {
    text-transform: uppercase;
  }

  &.btn--nowrap {
    white-space: nowrap;
  }

  &--attach {
    position: relative;
    color: $btn;
    background: $white;
    border: 2px solid $btn;
    padding-left: 50px;

    &:before {
      content: '';
      position: absolute;
      background: url("img/services/add_violet.svg") no-repeat center;
      width: 21px;
      height: 21px;
      left: 15px;
      top: 50%;
      margin-top: -11px;
    }

    &:hover {
      color: $btn-hover;
      border-color: $btn-hover;
      background: transparent;
    }

    &:hover:before {
      background: url("img/svg_icons/services/add_gray.svg") no-repeat center;
    }

  }

}


ul.custom-list {
  overflow: hidden;

  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 20px;

    &:before {
      position: absolute;
      left: 0;
      top: 4px;
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 2px solid $btn;
      border-radius: 10px;
      box-sizing: border-box;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.custom-html {
  font-family: $roboto;
  font-weight: 300;
  font-size: 1em;

  ul {
    overflow: hidden;

    li {
      position: relative;
      margin-bottom: 15px;
      padding-left: 20px;

      &:before {
        position: absolute;
        left: 0;
        top: 4px;
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 2px solid $btn;
        border-radius: 10px;
        box-sizing: border-box;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


.more-btn {
  text-decoration: none;
  position: relative;
  //margin: auto;
  font-family: $ubuntu;
  font-weight: 400;
  color: $btn-hover;
  padding-right: 32px;
  white-space: nowrap;

  &:after {
    content: '';
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    background: url("img/svg_icons/arrow_violet_right.svg") no-repeat;
    width: 20px;
    height: 14px;
  }
}
