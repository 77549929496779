.section {
	padding-bottom: 166px;
	padding-top: 30px;
	@media screen and (max-width: 500px) {
		padding-top: 0;
	}

	@media screen and (max-width: 500px) {
		padding-bottom: 50px;
	}
}

.section_empty {
	padding-top: 100px;
	@media screen and (max-width: 768px) {
		padding-top: 50px;
	}
}

.list_container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
}

.list_item {
	width: calc(50% - 62px);
	min-width: 300px;
	padding-bottom: 55px;
	@media screen and (max-width: 768px) {
		padding-bottom: 10px;
		width: 100%;
		min-width: 0;
	}

	&:last-child {
		@media screen and (max-width: 768px) {
			padding-bottom: 55px;
		}
	}
}

.list_item__title {
	font-size: 30px;
	font-family: "Ubuntu Regular", sans-serif;
	color: rgb(0, 0, 0);
	line-height: 1.2;
	font-weight: 400;
}

.list_item__ul_li {
	font-size: 15px;
	font-family: "Roboto Regular", sans-serif;
	color: rgb(0, 12, 18);
	line-height: 1.2;
}

.link_as_button__blue {
	font-size: 15px;
	font-family: "Ubuntu Medium", sans-serif;
	color: rgb(67, 38, 113);
	text-transform: uppercase;
	border-radius: 200px;
	border: 1px solid #432671;
	background: #fff;
	font-weight: 600;
	line-height: 1;
	padding: 13px 30px;
	cursor: pointer;
	transition: .3s;
	text-decoration: none;
	margin-right: 18px;
	display: flex;

	@media screen and (max-width: 500px) {
		margin-right: 0;
		margin-bottom: 20px;
	}

	&:hover {
		background: #432671;
		color: #fff;
	}
}