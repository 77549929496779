.title {
  font-size: 40px;
  font-family: "Ubuntu Regular", sans-serif;
  color: rgb(0, 0, 0);
  line-height: 1.2;
  margin-top: 105px;
  margin-bottom: 100px;
  font-weight: normal;
  max-width: 850px;

  @media screen and (max-width: 600px) {
    font-size: 34px;
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

.list_container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ul {
  width: calc(50% - 20px);
  padding-right: 20px;
  @media screen and (max-width: 768px) {
    padding-right: 0;
    width: 100%;
  }

  &:first-child {
    @media screen and (min-width: 768px) {
      margin-bottom: 35px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}


.li {
  font-size: 22px;
  font-family: "Ubuntu Regular", sans-serif;
  color: rgb(0, 0, 0);
  line-height: 1.364;
  position: relative;
  transition: .4s;

  &:not(:last-child) {
    @media screen and (max-width: 768px) {
      margin-bottom: 35px;
    }
  }
}

.li_left {
  @media screen and (min-width: 1024px) {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.li_right {
  @media screen and (min-width: 1024px) {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.active_li {
  @media screen and (min-width: 1024px) {
    opacity: 1 !important;
    transform: translateY(0);
  }
}


.mb_150 {
  margin-bottom: 150px;
}

.w100 {
  width: 100%;
}