.first_inf_section {
	background-color: rgb(246, 248, 249);
	padding-bottom: 50px;
	padding-top: 60px;
}

.first_info_title {
	font-size: 40px;
	font-family: "Ubuntu Regular", sans-serif;
	color: rgb(67, 38, 113);
	line-height: 1.2;
	font-weight: 400;
}

.first_info_description {
	font-size: 15px;
	font-family: "Roboto Regular", sans-serif;
	font-weight: 400;
	color: rgb(0, 12, 18);
	line-height: 1.2;
}

.first_info_container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.first_info_left {
	width: 605px;
	max-width: 100%;
	min-width: 300px;
	padding-right: 75px;
	@media screen and (max-width: 1200px) {
		width: 100%;
		padding-right: 0;
	}
	@media screen and (max-width: 500px) {
		min-width: 0;
	}
}

.first_info_image_desktop {
	margin-bottom: 50px;
	width: 100%;
	object-fit: contain;
}

.first_info_image_mobile {
	margin-top: -60px;
	width: calc(100% + 40px);
	max-width: 555px;
	margin-left: auto;
	margin-right: auto;
	object-fit: contain;

	@media screen and (max-width: 620px) {
		max-width: 620px;
		margin-left: -20px;
		margin-right: -20px;
	}
}

.first_info_right {
	width: 555px;
	max-width: 100%;
	min-width: 300px;
	padding-top: 111px;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 500px) {
		padding-top: 30px;
		min-width: 0;
		width: 100%;
	}
}

.short_info {

	@media screen and (max-width: 500px) {
		display: flex;
		flex-direction: column;
	}
}

.short_info_row {
	margin-bottom: 15px;
	@media screen and (max-width: 500px) {
		display: flex;
		flex-direction: column;
	}
}

.short_info_row_title {
	font-size: 18px;
	font-family: "Ubuntu Regular", sans-serif;
	color: rgb(157, 166, 177);
	line-height: 1.2;
	padding-right: 60px;
	max-width: calc(50% - 60px);
	padding-bottom: 15px;

	@media screen and (max-width: 500px) {
		max-width: 100%;
	}

}

.short_info_row_value {
	font-size: 18px;
	font-family: "Ubuntu Regular", sans-serif;
	color: rgb(67, 38, 113);
	line-height: 1.2;
	max-width: 50%;
	padding-bottom: 15px;

}

.blue_button {
	border-radius: 200px;
	background: #432671;
	font-size: 15px;
	font-family: "Ubuntu", sans-serif;
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	font-weight: 500;
	line-height: 1;
	padding: 13px 30px;
	cursor: pointer;
	transition: .3s;
	display: flex;

	&:hover {
		background: #909090;
	}
}

.margin_r {
	margin-right: 80px;
	@media screen and (max-width: 500px) {
		margin-right: 0;
	}
}

.short_info__button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 8px;
	@media screen and (max-width: 500px) {
		flex-direction: column;
		align-items: center;
	}
}
