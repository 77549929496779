.people {
  position: relative;
  padding: 0 0 185px 0;

  @media screen and (max-width: 600px) {
    padding: 0 0 90px 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: -50%;
    top: 0;
    background-color: #f6f8f9;
    width: 200%;
    height: 100%;
    z-index: -1;
  }

  //&:after{
  //  position: absolute;
  //  content:'';
  //  height: 100%;
  //  width: auto;
  //  background: linear-gradient(#e66465, #9198e5);
  //  right: 0;
  //}

  &-title {
    margin: 137px 0 131px 0;

    font-family: "Ubuntu Regular", sans-serif;
    font-size: 40px;
    line-height: 1.2;
    font-weight: normal;


    @media screen and (max-width: 600px) {
      margin: 100px 0 40px 0;
      font-size: 34px;
    }
  }


  &-slider {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1024px) {
      justify-content: center;
      flex-direction: column;
    }

    &_wrapper {
      width: calc(100% + 240px);
      position: relative;

      @media screen and (max-width: 1440px) {

      }
      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      &:before {
        position: absolute;
        content: '';
        top: 0;
        height: 100%;
        width: 400px;
        background: linear-gradient(277deg, rgba(246, 248, 249, 0.3) 5%, rgba(246, 248, 249, 0.1) 5%, #f6f8f9 60%);;
        right: 0;
        z-index: 1;
        transform: rotate(180deg);

        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
    }

    &_info {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 1024px) and (min-width: 769px) {
        width: 100%;
      }
    }

    $image_size: 130px;
    $image_size-adaptive: 95px;

    &_photo {
      width: $image_size;
      height: $image_size;
      margin-bottom: 23px;

      background-position: center;
      background-size: cover;
      border-radius: 100%;

      position: relative;

      &:before {
        width: $image_size;
        height: $image_size;
        position: absolute;
        content: '';
        border-radius: 100%;
        z-index: -1;
        bottom: -3px;
        right: -3px;
        background: #432671;

        @media screen and (max-width: 1024px) {
          width: $image_size-adaptive;
          height: $image_size-adaptive;
        }
      }

      @media screen and (max-width: 1024px) {
        width: $image_size-adaptive;
        height: $image_size-adaptive;
      }

    }

    &_name {
      font-family: "Ubuntu", sans-serif;
      font-size: 18px;
      line-height: 1.2;
      @media screen and (max-width: 1024px) and (min-width: 769px) {
        padding-left: 10px;
      }
    }

    &_position {
      font-family: "Ubuntu", sans-serif;
      font-size: 16px;
      color: #687482;
      line-height: 1.2;
      @media screen and (max-width: 1024px) and (min-width: 769px) {
        padding-left: 10px;
      }
    }

    &_text {
      font-family: "Roboto Light", sans-serif;
      position: relative;
      padding-left: 40px;
      font-size: 20px;
      line-height: 1.2;

      .quotes {
        position: absolute;
        content: '';
        left: 0;
        font-size: 50px;
        color: #432671;
      }

      p {
        max-width: 460px;
      }
    }
  }
}

.item {
  @media screen and (max-width: 910px) {
    display: flex;
    justify-content: center;
  }
}

.next-button,
.prev-button {
  width: 40px;
  height: 30px;
  cursor: pointer;

  border: 1px solid #e3e4e5;
  background: #FFF;
}

.next-button {
  position: absolute;
  right: 439px;
  bottom: -112px;
  transition: .4s;

  &:hover {
    transform: scale(1.1);
  }

  &:after {
    display: block;
    position: absolute;
    content: '';
    background: url("/img/svg_icons/arrow_violet_right.svg") no-repeat;
    width: 20px;
    height: 14px;
    top: 25%;
    right: 10px;
  }

  @media screen and (max-width: 1024px) {
    right: 90px;
  }
}

.prev-button {
  position: absolute;
  right: 479px;
  bottom: -112px;
  transition: .4s;

  &:hover {
    transform: scale(1.1);
  }

  &:before {
    display: block;
    position: absolute;
    content: '';
    background: url("/img/svg_icons/arrow_violet_left.svg") no-repeat;
    width: 20px;
    height: 14px;
    top: 25%;
    left: 10px;
  }

  @media screen and (max-width: 1024px) {
    right: 128px;
  }
}

//.next-button:hover,
//.prev-button:hover{
//  border: 2px solid #4d5054;
//  z-index: 44;
//  transition: all 0.3s ease-in-out;
//}


.custom-dots {
  position: absolute;
  bottom: -117px;
  left: 173px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;

  display: flex !important;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    left: 132px;
  }

  @media screen and (max-width: 800px) {
    left: 50%;
    transform: translateX(-50%)
  }

  @media screen and (max-width: 600px) {
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
  }


  li {
    padding: 0 7px !important;
    margin-bottom: 0 !important;
    cursor: pointer;

    &:before {
      display: none !important;
    }

    &.slick-active {
      div {
        background: #432671 !important;
      }
    }

    &:hover {
      div {
        background: #4d5054 !important;
      }
    }
  }
}

.slick-slide {
  padding-right: 20px;
}


.people.people-home {

  .people-slider_info {
    max-width: 167px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }

  .people-slider_text {
    font-size: 18px;
    line-height: 1.5;
  }

  &:before {
    background-color: #fff;
  }

  .people-slider_name {
    font-weight: bold;
    color: #432671;
  }

  .people-title {
    margin-top: 0;
    text-align: center;
  }

  .people-slider_wrapper {
    &:before {
      background: linear-gradient(277deg, rgba(255, 255, 255, 0.3) 5%, rgba(255, 255, 255, 0.1) 5%, #fff 60%);
    }
  }
}