@mixin defaultSlider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  @include defaultSlider;
}

.slider {
  position: absolute;
  top: 9px;
  left: 0;
  width: 100%;
  @include defaultSlider;
}

.slider__track,
.slider__range,
.slider__leftValue,
.slider__rightValue {
  @include defaultSlider;
  position: absolute;
}

.slider__track,
.slider__range {
  @include defaultSlider;
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  @include defaultSlider;
  width: 100%;
  z-index: 1;
  background-color: rgb(217, 219, 233);
  border-radius: 100px;
  height: 10px;
}

.slider__range {
  @include defaultSlider;
  background-color: #9fe5e1;
  z-index: 2;
  background-image: linear-gradient(29deg, rgb(67, 38, 113) 0%, rgb(42, 38, 113) 100%);
  height: 10px;
}

.slider__leftValue,
.slider__rightValue {
  font-size: 18px;
  font-family: "Ubuntu", sans-serif;
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin-top: 20px;
  top: 36px;
  @include defaultSlider;
}

.slider__leftValue {
  left: 0;
  @include defaultSlider;
}

.slider__rightValue {
  right: 0;
  @include defaultSlider;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  @include defaultSlider;
}

.thumb {
  @include defaultSlider;
  pointer-events: none;
  position: absolute;
  height: 0;
  outline: none;
  left: -1px;
  top: 14px;
  width: calc(100% - 19px);
}

.thumbLeft {
  z-index: 3;
  //padding-left: 28px;
  @include defaultSlider;
}

.thumbRight {
  z-index: 4;
  right: -1px;
  left: unset;
  //padding-right: 28px;
  @include defaultSlider;
}

@mixin thumbSlider {
  border: 5px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 28px;
  width: 28px;
  pointer-events: all;
  position: relative;
  background-image: linear-gradient(29deg, rgb(67, 38, 113) 0%, rgb(42, 38, 113) 100%);


  @include defaultSlider;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  @include thumbSlider;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  @include thumbSlider;
}

.slider__range_value_input {
  width: 60px;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  background-color: rgb(217, 219, 233);
  font-size: 18px;
  font-family: "Ubuntu", sans-serif;
  color: rgb(0, 0, 0);
  font-weight: 500;
  border: none;
  outline: 0;
}
