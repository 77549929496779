@mixin Mixin_image() {
	max-width: 630px;
	object-position: bottom center;
	transition: transform .3s;
	display: block;

	@media screen and (max-width: 1440px) {
		max-width: 430px;
	}

	@media screen and (max-width: 1366px) {
		max-width: 390px;
	}

	@media screen and (max-width: 1280px) {
		max-width: 340px;
	}

	@media screen and (max-width: 768px) {
		max-width: 320px;
	}

	@media screen and (max-width: 768px) {
		max-width: 300px;
	}

	@media screen and (max-width: 600px) {
		max-width: 310px;
	}
}

.top, .down {
	display: flex;
	flex-direction: row;
	width: max-content;
}

.img {

	@include Mixin_image;

	margin-right: 25px;

	@media screen and (max-width: 768px) {
		margin-right: 10px;
	}

	&:hover {
		transform: scale(calc(1.05));
	}

}

.down {
	margin-top: 25px;

	.img, .img_banner {
		object-position: top center;
	}


}

.fit_contain {
	object-fit: contain;
}

.fit_cover {
	object-fit: cover;
}

.gallery {
	width: unset;

	@media screen and (max-width: 600px) {
		padding-top: 70px;
	}

}

.gallery_banner {
	width: max-content;

	@media screen and (max-width: 600px) {
		padding-top: 70px;
	}
}

.section {
	display: flex;
	flex-direction: row;
	@media screen and (min-width: 1024px) {
		padding-top: 170px;
	}
	@media screen and (max-width: 1024px) {
		padding-top: 70px;
	}
	@media screen and (max-width: 768px) {
		padding-top: 30px;
	}
}

.img_banner {
	@include Mixin_image;

	&:not(:first-child) {
		margin-left: 25px;
		@media screen and (max-width: 768px) {
			margin-left: 10px;
		}
	}

	&:hover {
		transform: scale(1.05);
	}

}