.section {
  overflow: visible;
}

.w100 {
  width: 100%;
  overflow-y: unset;
  @media screen and (max-width: 1024px) {
    overflow-y: auto;
  }
}


.vacancies {
  padding-bottom: 182px;
  padding-top: 64px;
  z-index: 0;
  text-align: left;
  position: relative;

  @media screen and (max-width: 500px) {
    padding-top: 0;
    padding-bottom: 90px;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .vacancy {

    &_link {
      background-color: #432671;
      border-radius: 100px;
      padding: 11px 44px;
      font-size: 16px;
      line-height: 1;
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      transition: .3s;
      text-align: center;

      &:hover {
        background-color: #909090;
        transition: .3s;
      }
    }

    &_container {
      display: flex;
      flex-direction: column;
      padding-top: 28px;
      padding-bottom: 30px;
      border-bottom: 2px solid rgb(227, 228, 229);
      position: relative;
      z-index: 9;
      cursor: pointer;
      text-decoration: none;

      & > span {
        //display: inline-block;
      }

      @media screen and (max-width: 1200px) {
        width: calc(100% - 44px);
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (max-width: 500px) {
        padding-top: 0;
        width: 100%;
      }

      @media screen and (max-width: 600px) {
        //display: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        //transition: .5s;
        width: calc(100% + 44px);
        height: calc(100% + 8px);
        left: -22px;
        top: -4px;
        border-radius: 10px;
      }

      @media screen and (min-width: 601px) {
        &:hover {
          z-index: 10;

          &:before {
            box-shadow: 0 0 29px rgba(0, 0, 0, .27);
            background: #fff;
          }

          .vacancy_title {
            font-weight: bold;
          }

          .vacancy_top__link {
            color: rgb(255, 255, 255);
            background-color: rgb(67, 38, 113);
            position: relative;

            &:hover {
              background: #909090;
              border-color: #909090;
            }

            &:after {
              content: '';
              position: absolute;
              right: 24px;
              top: 0;
              background: url("/img/vacancies/arrow_btn_w.svg") center no-repeat;
              background-size: 20px 14px;
              height: 100%;
              width: 20px;
              opacity: 1;
              transition: .3s;
            }
          }

          .vacancy_info__item_desc {
            font-weight: bold;
          }

        }
      }

    }

    &_top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
      }

      &__link {
        border-radius: 100px;
        padding: 11px 55px 11px 25px;
        font-size: 15px;
        text-transform: uppercase;
        line-height: 1;
        text-decoration: none;
        transition: .3s;
        position: relative;
        border-width: 1px;
        border-color: rgb(67, 38, 113);
        color: rgb(67, 38, 113);
        border-style: solid;
        background-color: rgb(255, 255, 255);

        @media screen and (max-width: 1024px) {
          width: max-content;
          margin-top: 20px;
        }

        @media screen and (max-width: 600px) {
          background: rgb(67, 38, 113);
          margin-top: 0;
          color: #fff;
          &:after {
            content: '';
            position: absolute;
            right: 24px;
            top: 0;
            background: url("/img/vacancies/arrow_btn_w.svg") center no-repeat;
            background-size: 20px 14px;
            height: 100%;
            width: 20px;
            opacity: 1;
            transition: .3s;
          }
        }


        &:before {
          content: '';
          position: absolute;
          right: 24px;
          top: 0;
          background: url("/img/vacancies/arrow_btn.svg") center no-repeat;
          background-size: 20px 14px;
          height: 100%;
          width: 20px;
          opacity: 1;
          transition: .3s;
        }

      }
    }

    &_title {
      font-size: 26px;
      font-family: "Ubuntu Regular", sans-serif;
      font-weight: normal;
      color: rgb(0, 0, 0);
      line-height: 1.2;


      &.hot_title {
        padding-left: 35px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: url("/img/vacancies/fire_ico.svg") center no-repeat;
          height: 100%;
          width: 24px;
          background-size: 22px 35px;
          left: 0;
          bottom: 6px;
        }
      }

    }


    &_info {
      width: 100%;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
      }

      &__item {
        display: flex;
        flex-direction: row;


        &:not(:first-child) {
          margin-left: 85px;
          @media screen and (max-width: 1024px) {
            margin-left: 0;
          }
        }


        @media screen and (max-width: 1024px) {
          width: calc(50% - 20px);
          margin-bottom: 20px;
          padding-right: 20px;
        }


        @media screen and (max-width: 500px) {
          width: 100%;
          padding-right: 0;
          flex-direction: column;
        }

        &_name {
          font-size: 16px;
          font-family: "Roboto", sans-serif;
          color: rgb(157, 166, 177);
          line-height: 1.2;
          padding-right: 12px;

          @media screen and (max-width: 500px) {
            padding-right: 0;
            padding-bottom: 5px;
          }
        }

        &_desc {
          font-size: 16px;
          font-family: "Roboto", sans-serif;
          color: rgb(67, 38, 113);
          line-height: 1.2;
        }
      }
    }
  }
}

.limitedLastItem {
  margin-bottom: 64px;
}

.link_more_center {
  display: flex;
  justify-content: center;
}

.link_as_all_block {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
  }
}

.link_as_all_block_career {
  margin-top: 40px;
}