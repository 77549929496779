.gradient_violet {
	background-image: linear-gradient(61deg, rgb(67, 38, 113) 0%, rgb(42, 38, 113) 100%);
	width: 100%;
	max-width: 785px;
	padding: 150px 180px 150px 100px;
	font-size: 55px;
	font-family: "Ubuntu Regular", sans-serif;
	color: rgb(255, 255, 255);
	line-height: 1.255;
	display: block;

	@media screen and (max-width: 600px) {
		padding: 60px 20px !important;
		margin-left: -20px;
		margin-right: -20px;
		width: calc(100% + 40px);
	}
}

.position_relative {
	position: relative;
}

.max_size100 {
	max-width: 100%;
	max-height: 100%;
}

.position_absolute {
	position: absolute;
	left: 0;
	bottom: 0;
}

.mb_150 {
	margin-bottom: 150px;
}

.mb_minus_150 {
	margin-bottom: -150px;
}

.lite_parallax {
	@media screen and (min-width: 1025px) {
		height: max-content;
		width: 100%;
		background-attachment: fixed;
		background-position: top center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	@media screen and (max-width: 1025px) {
		height: max-content;
		width: 100%;
		background-size: cover;
	}
	@media screen and (max-width: 830px) {
		display: none;
	}
}

.media_cover {
	object-fit: cover;
	object-position: center;
}

.opacityNull {
	opacity: 0;
}

.z_index_minus_1 {
	z-index: -1;
}

.z_index_1 {
	z-index: 1;
}

.df {
	display: flex;
}

.fdc {
	flex-direction: column;
}

.w100 {
	width: 100%;
}

.title {
	font-weight: normal;
}

.img {
	@media screen and (max-width: 830px) {
		display: none;
	}
}

.position {
	@media screen and (max-width: 830px) {
		position: static;
	}
}

.title_adaptive {
	@media screen and (max-width: 8300px) {
		margin-top: 0;
		padding: 100px 50px;
	}

	@media screen and (max-width: 600px) {
		font-size: 45px;
	}
}
